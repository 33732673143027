import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout.js"
import SEO from "../components/seo"
import { Row, Col, Accordion, Card } from 'react-bootstrap'

class Toggle extends React.Component {
  render () {
      return <Card key={this.props.id} className="col-md-10 col-sm-12">
              <Accordion.Toggle as={Card.Header} eventKey={this.props.eventKey} variant="link">
                <div>
                  <h3>{this.props.date} {this.props.title}</h3>
                </div>
                <div>
                  <h2>{this.props.eventTitle}</h2> 
                </div>
              </Accordion.Toggle>
              <Collapse eventKey={this.props.eventKey} content={this.props.content} facebook={this.props.facebook}></Collapse>
            </Card>
  }
}

class Collapse extends React.Component {
  render () {
    return <Accordion.Collapse eventKey={this.props.eventKey}>
              <Card.Body>
                  <div dangerouslySetInnerHTML={{ __html: this.props.content }} />
                  {this.props.facebook ? (
                      <a href={this.props.facebook} target="_blank" rel="noopener noreferrer">→ Event on Facebook</a>
                  ) : (
                    ``
                  )}
              </Card.Body>
           </Accordion.Collapse>
  }
}


const EventsTemplate = ({ data }) => (
  <Layout>
    <SEO
      title={data.wordpressPage.title}
      description={data.wordpressPage.excerpt}
    />
    <Row>
      <Col md={12} lg={10}>
        <div dangerouslySetInnerHTML={{ __html: data.wordpressPage.content }} />
      </Col>
    </Row>
    <Accordion className="row mt-4" defaultActiveKey="0">
      {data.allWordpressWpEvents.edges.map((post, index) =>(
            <Toggle key={post.node.id} eventKey={index} date={post.node.acf.date} title={post.node.title} eventTitle={post.node.acf.event_title} content={post.node.acf.content} facebook={post.node.acf.facebook_link}>
            </Toggle>
      ))}
    </Accordion>
  </Layout>
)
export default EventsTemplate

export const query = graphql`
  query {
    wordpressPage(slug: { eq: "wurm" }) {
      title
      excerpt
      content
      slug
    }
    allWordpressWpEvents(sort: {fields: acf___date, order: DESC}) {
        edges {
          node {
            id
            slug
            wordpress_id
            title
            acf {
              date(locale: "de", formatString: "D.M.Y")
              event_title
              content
              facebook_link
          }
        }
      }
    }
}
`